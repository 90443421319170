import React, { useRef, useState } from 'react'
import './app.scss'
import RestartSVG from './restart'
import { Transition } from 'react-transition-group'

function App() {
  const [winner,setWinner] = useState(0)
  const [playerNameInputs,setPlayerNameInputs] = useState(true)
  const [turn,setTurn] = useState(true)
  const [player1,setPlayer1] = useState({
    "name":"Player 1",
    "boxes":[],
    "win":0
  })
  const [player2,setPlayer2] = useState({
    "name":"Player 2",
    "boxes":[],
    "win":0
  })
  const initialBox = [
    { "id":0, "player":null },
    { "id":1, "player":null },
    { "id":2, "player":null },
    { "id":3, "player":null },
    { "id":4, "player":null },
    { "id":5, "player":null },
    { "id":6, "player":null },
    { "id":7, "player":null },
    { "id":8, "player":null },
  ]
  const [box,setBox] = useState(initialBox)
  const winingNumbers = [
    [0,1,2],
    [3,4,5],
    [6,7,8],
    [0,3,6],
    [1,4,7],
    [2,5,8],
    [0,4,8],
    [2,4,6]
  ]
  const nodeRef = useRef(null)

  const checkWinner = (playerBoxes) => {
    let ret = false
    winingNumbers.forEach((arr)=>{
      if(playerBoxes.includes(arr[0]) && playerBoxes.includes(arr[1]) && playerBoxes.includes(arr[2])){
        ret = true
      }
    })
    return ret
  }

  const selectBox = (id) => {
    if(box[id].player===null){
      let newArr = [...box]
      let $player_turn = turn ? "1" : "2"
      newArr[id]={ "id":id, "player":$player_turn }
      setBox(newArr)
      setTurn(!turn)
      if($player_turn==="1"){
        let playerBoxes = [...player1.boxes, id]
        setPlayer1((prev)=>({...prev,"boxes":playerBoxes}))
        if(playerBoxes.length>2){
          if(checkWinner(playerBoxes)){
            setPlayer1((prev)=>({...prev,"win":player1.win+1}))
            setWinner(1)
          }
        }
      }else{
        let playerBoxes = [...player2.boxes, id]
        setPlayer2((prev)=>({...prev,"boxes":playerBoxes}))
        if(playerBoxes.length>2){
          if(checkWinner(playerBoxes)){
            setPlayer2((prev)=>({...prev,"win":player2.win+1}))
            setWinner(2)
          }
        }
      }
    }
  }
  
  const restartGame = () => {
    setBox(initialBox)
    setTurn(true)
    setPlayer1((pr)=>({...pr,"boxes":[]}))
    setPlayer2((pr)=>({...pr,"boxes":[]}))
    setWinner(0)
  }

  return (
    <>
      <Transition in={winner>0} timeout={300} mountOnEnter unmountOnExit nodeRef={nodeRef}>
          {state => (
              <div className={`winner ${state}`} ref={nodeRef}>
                <div className="winner_box">
                  <span>YOU WON!</span>
                  <p className={winner === 1 ? "player1" : "player2"}>{ winner === 1 ? player1.name : player2.name }</p>
                  <div className="again" onClick={restartGame}>Next Round</div>
                </div>
              </div>
          )}
      </Transition>
      {playerNameInputs && <div className="player_names">
        <p>Enter Player 1 name (X)</p>
        <input type="text" onChange={(e)=>setPlayer1((prev)=>({...prev,"name":e.target.value}))} />
        <p className="second_player">Enter Player 2 name (O)</p>
        <input type="text" onChange={(e)=>setPlayer2((prev)=>({...prev,"name":e.target.value}))} />
        <div className="default_btn" onClick={()=>setPlayerNameInputs(false)}>Done</div>
      </div>}
      <div className="bg">
        <div className="container">
          <div className="top">
            <div className={`turn_info ${turn ? "player1" : "player2"}`}>
              { turn ? player1.name : player2.name }
            </div>
            <div className="restart_btn" onClick={restartGame}>
              <RestartSVG />
              Reastart
            </div>
          </div>
          <div className="game_area">
            {
              box.map((item)=>(
                <div className={`box_container ${box[item.id].player==="1" ? "add_times" : ""} ${box[item.id].player==="2" ? "add_cyrcle" : ""}`} key={item.id}>
                  <div className="box" onClick={()=>selectBox(item.id)}></div>
                  <div className="cyrcle">
                    <div className="left"></div>
                    <div className="right"></div>
                  </div>
                  <div className="times">
                    <div className="left"></div>
                    <div className="right"></div>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="game_summary">
            <div className="block">
              <p>{player1.name} (X)</p>
              <h5>{player1.win}</h5>
            </div>
            <div className="block">
              <p>{player2.name} (O)</p>
              <h5>{player2.win}</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
