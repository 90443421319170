const RestartSVG = () =><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none"><path d="M2390 5113 c-309 -19 -663 -115 -955 -258 -366 -180 -651 -413 -902
-737 l-75 -97 -114 86 c-63 47 -130 91 -150 98 -67 23 -143 -10 -176 -76 -17
-31 -18 -91 -18 -814 0 -870 -4 -824 69 -872 74 -49 64 -53 756 295 471 237
635 324 650 345 26 37 27 122 2 160 -10 15 -78 73 -152 128 -74 56 -135 105
-135 108 0 17 124 173 192 242 263 265 576 420 957 473 384 54 797 -32 1113
-230 344 -216 585 -536 699 -927 52 -179 63 -264 63 -482 0 -214 -13 -309 -68
-490 -168 -557 -608 -971 -1186 -1115 -565 -142 -1174 30 -1582 447 -146 149
-213 245 -323 463 -95 188 -171 262 -314 306 -70 22 -194 19 -266 -6 -122 -42
-231 -144 -276 -258 -31 -78 -38 -220 -14 -294 28 -85 116 -264 188 -380 398
-650 1052 -1080 1822 -1200 187 -29 541 -31 715 -5 576 89 1074 334 1465 722
755 747 958 1924 504 2912 -431 937 -1429 1521 -2489 1456z"/></g></svg>

export default RestartSVG